<template>
  <div>
    <!-- modal form input and edit -->
    <b-modal id="modal-form-ormas" ok-only centered size="xl" content-class="shadow" hide-header hide-footer>
      <div>
        <b-container fluid class="pl-0 pr-0">
          <b-row class="pt-5 mt-2 mb-4">
            <b-col class="d-flex justify-content-between pr-0">
              <b style="font-size:20px;">
                {{activeTab == 0 ?'Profil Ormas WNA' : ''}}
                {{activeTab == 1 ?'Lokus Kegiatan Ormas WNA' : ''}}
                {{activeTab == 2 ?'Kemitraan Ormas WNA' : ''}}
                {{activeTab == 3 ?'Tenaga Kerja Asing' : ''}}
                {{activeTab == 4 ?'Pelaporan Ormas WNA' : ''}}
              </b>
              <div>
                <b-button variant="outline-secondary" style="background: #FFFFFF;" size="sm" class="mr-4"
                  @click="resetForm()">
                  <v-icon class="pr-3">mdi-close</v-icon>
                  Tutup
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-tabs pills card justified v-model="activeTab" style="border-radius:10px;">
            <b-tab active title="Profil" style="background-color:#FAFAFA;margin-top:0px;">
              <b-row> 
                <input style="display: none;" @change="uploadFile" type="file" ref="fileInput"/>
                <b-col cols="12">
                  <b-row class="pt-3 pb-3 align-items-center">
                    <b-col sm="3">
                      <p style="font-weight: 600; margin:0px;" class="required">{{ "Nama Ormas WNA" }}</p>
                    </b-col>
                    :
                    <b-col sm="6">
                      <b-form-input v-model="formprofilAsing.namaOrmas" placeholder="silahkan masukkan nama ormas asing" :class="{'is-invalid': $v.formprofilAsing.namaOrmas.$error}"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="pt-3 pb-3 align-items-center">
                    <b-col sm="3">
                      <p style="font-weight: 600; margin:0px;" class="required">{{ "Negara" }}</p>
                    </b-col>
                    :
                    <b-col sm="6">
                      <model-select :options="optionsNegara"
                        v-model="formprofilAsing.negaraKode"
                        :class="{'is-invalid': $v.formprofilAsing.negaraKode.$error}"
                        placeholder="silahkan pilih negara">
                      </model-select>
                    </b-col>
                  </b-row>
                  <b-row class="pt-3 pb-3 align-items-center">
                    <b-col sm="3">
                      <p style="font-weight: 600; margin:0px;" class="required">{{ "Benua" }}</p>
                    </b-col>
                    :
                    <b-col sm="6">
                      <b-form-select v-model="formprofilAsing.benua" :class="{ 'is-invalid': $v.formprofilAsing.benua.$error}">
                        <b-form-select-option value="" disabled>silahkan pilih benua</b-form-select-option>
                        <b-form-select-option value="Afrika">Afrika</b-form-select-option>
                        <b-form-select-option value="Amerika Utara">Amerika Utara</b-form-select-option>
                        <b-form-select-option value="Amerika Selatan">Amerika Selatan</b-form-select-option>
                        <b-form-select-option value="Antartika">Antartika</b-form-select-option>
                        <b-form-select-option value="Asia">Asia</b-form-select-option>
                        <b-form-select-option value="Australia">Australia</b-form-select-option>
                        <b-form-select-option value="Eropa">Eropa</b-form-select-option>
                      </b-form-select>
                    </b-col>
                  </b-row>
                  <b-row class="pt-3 pb-3 align-items-center">
                    <b-col sm="3">
                      <p style="font-weight: 600; margin:0px;" class="required">{{ "Nomor MSP" }}</p>
                    </b-col>
                    :
                    <b-col sm="6">
                      <b-form-input v-model="formprofilAsing.nomorMsp" placeholder="silahkan masukkan nomor msp" :class="{'is-invalid': $v.formprofilAsing.nomorMsp.$error}"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="pt-3 pb-3 align-items-center">
                    <b-col sm="3">
                      <p style="font-weight: 600; margin:0px;" class="required">{{ "Unggah MSP" }}</p>
                    </b-col>
                    :
                    <b-col sm="2">
                      <b-button variant="primary" size="sm" v-if="!formprofilAsing.fileNomorMsp || formprofilAsing.fileNomorMsp == ''"
                        @click="fileClicked('msp')" :class="{'is-invalid': $v.formprofilAsing.fileNomorMsp.$error}">
                        <v-icon>mdi-upload</v-icon> Unggah MSP
                      </b-button>
                      <a v-if="formprofilAsing.fileNomorMsp && formprofilAsing.fileNomorMsp != ''"
                        :href="fileURL + formprofilAsing.fileNomorMsp" target="_blank" type="button">
                        <b-button variant="success" size="sm">
                          Lihat File
                        </b-button>
                      </a>
                      <b-button v-if="formprofilAsing.fileNomorMsp && formprofilAsing.fileNomorMsp != ''"
                        @click="formprofilAsing.fileNomorMsp = ''" variant="danger" size="sm" class="ml-3">
                        <v-icon class="p-0">mdi-delete-outline</v-icon>
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row class="pt-3 pb-3 d-flex align-items-center">
                    <b-col sm="3">
                      <p style="font-weight: 600; margin:0px;" class="required">{{ "Unggah AP" }}</p>
                    </b-col>
                    :
                    <b-col sm="2">
                      <b-button variant="primary" size="sm" v-if="!formprofilAsing.fileAp || formprofilAsing.fileAp == ''"
                        @click="fileClicked('ap')" :class="{'is-invalid': $v.formprofilAsing.fileAp.$error}">
                        <v-icon>mdi-upload</v-icon> Unggah AP
                      </b-button>
                      <a v-if="formprofilAsing.fileAp && formprofilAsing.fileAp != ''" :href="fileURL + formprofilAsing.fileAp"
                        target="_blank" type="button">
                        <b-button variant="success" size="sm">
                          Lihat File
                        </b-button>
                      </a>
                      <b-button v-if="formprofilAsing.fileAp && formprofilAsing.fileAp != ''" @click="formprofilAsing.fileAp = ''"
                        variant="danger" size="sm" class="ml-3">
                        <v-icon class="p-0">mdi-delete-outline</v-icon>
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row class="pt-3 pb-3 d-flex align-items-center">
                    <b-col sm="3">
                      <p style="font-weight: 600; margin:0px;" class="required">{{ "Unggah RIK" }}</p>
                    </b-col>
                    :
                    <b-col sm="2">
                      <b-button variant="primary" size="sm" v-if="!formprofilAsing.fileRik || formprofilAsing.fileRik == ''"
                        @click="fileClicked('pik')" :class="{'is-invalid': $v.formprofilAsing.fileRik.$error}">
                        <v-icon>mdi-upload</v-icon> Unggah RIK
                      </b-button>
                      <a v-if="formprofilAsing.fileRik && formprofilAsing.fileRik != ''" :href="fileURL + formprofilAsing.fileRik"
                        target="_blank" type="button">
                        <b-button variant="success" size="sm">
                          Lihat File
                        </b-button>
                      </a>
                      <b-button v-if="formprofilAsing.fileRik && formprofilAsing.fileRik != ''" @click="formprofilAsing.fileRik = ''"
                        variant="danger" size="sm" class="ml-3">
                        <v-icon class="p-0">mdi-delete-outline</v-icon>
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row :class="formprofilAsing.program.length == 1 ? 'pt-3 pb-3 align-items-center' : 'pt-3 pb-3 '">
                    <b-col sm="3">
                      <p style="font-weight: 600; margin:0px;" class="required">{{ "Program" }}</p>
                    </b-col>
                    :
                    <b-col sm="8">
                      <b-row v-for="(i,index) in formprofilAsing.program" :key="index" class="pb-2">
                        <b-col sm="9">
                          <b-form-input v-model="formprofilAsing.program[index].nama" placeholder="silahkan masukkan program ormas asing"
                            :state="validateStateProgram(index)"></b-form-input>
                        </b-col>
                        <b-col cols="3" class="d-flex align-items-center pl-0">
                          <div class="button-minus" v-if="index != 0 && formprofilAsing.program.length - index == 1">
                            <v-icon style="color:white;" small @click="formprofilAsing.program.splice(-1)">mdi-minus</v-icon>
                          </div>
                          <div class="button-plus" v-if="formprofilAsing.program.length - index != 1" style="opacity:0.5;">
                            <v-icon style="color:white;" small>mdi-plus</v-icon>
                          </div>
                          <div class="button-plus" v-if="formprofilAsing.program.length - index == 1">
                            <v-icon style="color:white;" small @click="formprofilAsing.program.push({nama: ''})">mdi-plus</v-icon>
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row class="pt-3 pb-3 align-items-center">
                    <b-col sm="3">
                      <p style="font-weight: 600; margin:0px;" class="required">{{ "Mulai MSP" }}</p>
                    </b-col>
                    :
                    <b-col sm="6">
                      <b-form-datepicker 
                        v-model="formprofilAsing.mulaiMsp"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                        placeholder="mulai"
                        style="height:40px;width:130px;" class="mb-2" :class="{'is-invalid': $v.formprofilAsing.mulaiMsp.$error}">
                      </b-form-datepicker>
                    </b-col>
                  </b-row>
                  <b-row class="pt-3 pb-3 align-items-center">
                    <b-col sm="3">
                      <p style="font-weight: 600; margin:0px;" class="required">{{ "Akhir MSP" }}</p>
                    </b-col>
                    :
                    <b-col sm="6">
                      <b-form-datepicker 
                        @input="calculateDateDiff()"
                        v-model="formprofilAsing.akhirMsp"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                        placeholder="akhir"
                        style="height:40px;width:130px;" class="mb-2" :class="{ 'is-invalid': $v.formprofilAsing.akhirMsp.$error}">
                      </b-form-datepicker>
                    </b-col>
                  </b-row>
                  <b-row class="pt-3 pb-3 align-items-center">
                    <b-col sm="3">
                      <p style="font-weight: 600; margin:0px;">{{ "Sisa Masa MSP" }}</p>
                    </b-col>
                    :
                    <b-col sm="6">{{this.sisaAktif}}</b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="mt-5">
                <b-col class="d-flex justify-content-center pt-5 mt-5">
                  <b-button variant="primary" size="lg" style="width:200px;" @click="lanjutProfile()">
                    Lanjut
                  </b-button>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Lokus Kegiatan" style="background-color:#FAFAFA;margin-top:0px;">
              <b-row>
                <b-col cols="12">
                  <b-row class="mt-5">
                    <b-col class="d-flex align-items-center justify-content-between">
                      <span style="font-size:16px; font-weight:bold;"></span>
                      <div>
                        <b-button size="sm" variant="primary" @click="tambahLokus()">
                          <v-icon style="color:white;" small>mdi-plus-box</v-icon>
                          Tambah Lokus
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                  <div class="table-responsive mt-4" style="border-radius:15px;">
                    <table class="table table-head-custom table-vertical-center table-head-bg table-bordered">
                      <thead>
                        <tr class="text-left">
                          <th style="width: 20px">No</th>
                          <th style="min-width: 50px" class="text-capitalize">Propinsi</th>
                          <th style="min-width: 50px" class="text-capitalize">Kabupaten/Kota</th>
                          <th style="min-width: 50px" class="text-capitalize">Kecamatan</th>
                          <th style="min-width: 50px" class="text-capitalize">Kelurahan/Desa</th>
                          <th style="width: 100px" class="text-capitalize">Aksi</th>
                        </tr>
                      </thead>
                      <tbody v-if="asingLokuses.length == 0">
                        <template v-if="!loadingTable">
                          <tr>
                            <td colspan="6"> -- Tidak ada data -- </td>
                          </tr>
                        </template>
                      </tbody>
                      <tbody style="background:#FAFAFA;" v-if="asingLokuses.length !== 0">
                        <template v-for="(item, index) in asingLokuses">
                          <tr v-bind:key="index">
                            <td>
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                {{ index + 1 }}
                              </span>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ item.masterProvinsi }}
                              </span>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ item.masterKabupatenKota }}</span>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ item.masterKecamatan }}</span>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ item.masterKelurahan }}</span>
                            </td>
                            <td class="d-flex justify-content-center">
                              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">
                                <section class="d-flex justify-content-center align-items-center">
                                  <b-button variant="primary" size="sm" class="d-flex justify-content-center align-items-center"
                                    style="width:30px;height:30px;margin:5px;" @click="editLokus(item, index)">
                                    <v-icon style="color:white;padding:0px;" small>mdi-pencil-outline</v-icon>
                                  </b-button>
                                  <b-button variant="danger" size="sm" class="d-flex justify-content-center align-items-center"
                                    style="width:30px;height:30px;margin:5px;" @click="lokusTempdata.splice(index, 1)">
                                    <v-icon style="color:white;padding:0px;" small>mdi-delete-outline</v-icon>
                                  </b-button>
                                  <b-button @click="toDetailLokus(item)" class="d-flex justify-content-center align-items-center" variant="success"
                                    size="sm" style="width:30px;height:30px;margin:5px;">
                                    >>
                                  </b-button>
                                </section>
                              </span>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-5">
                <b-col class="d-flex justify-content-center pt-5 mt-5">
                  <button class="mt-16 text-center button-back mr-5" @click="kembaliLokus()">
                    Kembali
                  </button>
                  <b-button class="mt-16 text-center" variant="primary" size="lg" style="width:200px;" @click="lanjutLokus()">
                    Lanjut
                  </b-button>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Kemitraan" style="background-color:#FAFAFA;margin-top:0px;">
              <b-row>
                <input style="display: none;" />
                <b-col cols="12">
                  <b-row style="font-weight: 800;">
                    A. DATA MITRA K/L
                  </b-row>
                  <b-row class="pt-3 pb-3 align-items-center">
                    <b-col sm="3">
                      <p style="font-weight: 600; margin:0px;" class="required">{{ "Mitra K/L" }}</p>
                    </b-col>
                    :
                    <b-col sm="6">
                      <b-form-input v-model="asingKemitraan.mitraKl" placeholder="silahkan masukkan nama mitra k/l"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="pt-3 pb-3 align-items-center">
                    <b-col sm="3">
                      <p style="font-weight: 600; margin:0px;" class="required">{{ "Nama Penandatangan MSP" }}</p>
                    </b-col>
                    :
                    <b-col sm="6">
                      <b-form-input v-model="asingKemitraan.mitraPejabat" placeholder="silahkan masukkan nama pejabat penandatangan"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="pt-3 pb-3 align-items-center">
                    <b-col sm="3">
                      <p style="font-weight: 600; margin:0px;" class="required">{{ "Jabatan Penandatangan MSP" }}</p>
                    </b-col>
                    :
                    <b-col sm="6">
                      <b-form-input v-model="asingKemitraan.mitraJabatan" placeholder="silahkan masukkan no mou"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row style="font-weight: 800;">
                    B. DATA MITRA SKPD
                  </b-row>
                  <div class="table-responsive">
                    <table class="table table-head-custom table-vertical-center table-head-bg table-bordered">
                      <thead>
                        <tr class="text-left">
                          <th style="width: 20px">No</th>
                          <th style="min-width: 50px" class="text-capitalize required">Nama SKPD</th>
                          <th style="min-width: 50px" class="text-capitalize">No RKT</th>
                          <th style="min-width: 50px" class="text-capitalize">Nama Pejabat Penandatangan</th>
                          <th style="min-width: 50px" class="text-capitalize">Unggah RKT</th>
                          <th style="width: 100px" class="text-capitalize">Aksi</th>
                        </tr>
                      </thead>
                      <tbody v-if="formmitraAsingskpd.length == 0">
                        <template v-if="!loadingTable">
                          <tr>
                            <td colspan="6"> -- Tidak ada data -- </td>
                          </tr>
                        </template>
                      </tbody>
                      <tbody style="background:#FAFAFA;"  v-if="formmitraAsingskpd.length !== 0">
                        <template v-for="(itemL, indexL) in formmitraAsingskpd">
                          <tr v-bind:key="indexL">
                            <td>
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                {{ indexL + 1 }}
                              </span>
                            </td>
                            <td>
                              <b-form-input v-model="itemL.nama" placeholder="silahkan masukkan nama mitra skpd"></b-form-input>
                            </td>
                            <td>
                              <b-form-input v-model="itemL.noRkt" placeholder="silahkan masukkan nomor rkt"></b-form-input>
                            </td>
                            <td>
                              <b-form-input v-model="itemL.namaPejabat" placeholder="silahkan masukkan nama pejabat penandatangan"></b-form-input>
                            </td>
                            <td>
                              <b-button variant="primary" size="sm" v-if="!itemL.fileRkt || itemL.fileRkt == ''" @click="fileClicked('rkt' + indexL)">
                                <v-icon>mdi-upload</v-icon> Unggah RKT
                              </b-button>
                              <a v-if="itemL.fileRkt && itemL.fileRkt != ''" :href="fileURL + itemL.fileRkt"
                                target="_blank" type="button">
                                <b-button variant="success" size="sm">
                                  Lihat File
                                </b-button>
                              </a>
                              <b-button v-if="itemL.fileRkt && itemL.fileRkt != ''" @click="itemL.fileRkt = ''"
                                variant="danger" size="sm" class="ml-3">
                                <v-icon class="p-0">mdi-delete-outline</v-icon>
                              </b-button>
                            </td>
                            <td class="d-flex justify-content-center">
                              <div class="button-plus" v-if="formmitraAsingskpd.length - indexL != 1" style="opacity:0.5;">
                                <v-icon style="color:white;" small>mdi-plus</v-icon>
                              </div>
                              <div class="button-minus" v-if="indexL != 0 && formmitraAsingskpd.length - indexL == 1">
                                <v-icon style="color:white;" small @click="formmitraAsingskpd.splice(-1)">mdi-minus</v-icon>
                              </div>
                              <div class="button-plus" v-if="formmitraAsingskpd.length - indexL == 1">
                                <v-icon style="color:white;" small @click="addSKPD()">mdi-plus</v-icon>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                  <b-row style="font-weight: 800;">
                    C. DATA MITRA ORMAS LOKAL
                  </b-row>
                  <div class="table-responsive">
                    <table class="table table-head-custom table-vertical-center table-head-bg table-bordered">
                      <thead>
                        <tr class="text-left">
                          <th style="width: 20px">No</th>
                          <th style="width: 252px;" class="text-capitalize required">Jenis Ormas Lokal</th>
                          <th style="min-width: 50px" class="text-capitalize required">Nama Ormas Lokal</th>
                          <th style="width: 100px" class="text-capitalize">Aksi</th>
                        </tr>
                      </thead>
                      <tbody v-if="ormasLokasdataForm.length == 0">
                        <template v-if="!loadingTable">
                          <tr>
                            <td colspan="6"> -- Tidak ada data -- </td>
                          </tr>
                        </template>
                      </tbody>
                      <tbody style="background:#FAFAFA;"  v-if="ormasLokasdataForm.length !== 0">
                        <template v-for="(itemo, indexo) in ormasLokasdataForm">
                          <tr v-bind:key="indexo">
                            <td>
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                {{ indexo + 1 }}
                              </span>
                            </td>
                            <td>
                              <b-form-select @input="jenisOrmas(indexo)" v-model="itemo.jenisormasLokal">
                                <!-- <b-form-select-option disabled hidden>silahkan masukkan jenis ormas</b-form-select-option> -->
                                <b-form-select-option value="1">Ormas Berbadan Hukum</b-form-select-option>
                                <b-form-select-option value="2">Ormas Tidak Berbadan Hukum</b-form-select-option>
                              </b-form-select>
                            </td>
                            <td v-if="itemo.jenisormasLokal == 0">
                                <b-form-input placeholder="silahkan masukkan nama ormas berbadan hukum">
                                </b-form-input>
                            </td>
                            <td v-if="itemo.jenisormasLokal == 1">
                                <b-form-input v-model="itemo.namaOrmasBh" placeholder="silahkan masukkan nama ormas berbadan hukum">
                                </b-form-input>
                            </td>
                            <td v-if="itemo.jenisormasLokal == 2">
                                <model-select :options="optionsOrmasSKT" v-model="itemo.idOrmasLokal" placeholder="silahkan masukkan nama ormas lokal">
                                </model-select>
                            </td>
                            <td class="d-flex justify-content-center">
                              <div class="button-plus" v-if="ormasLokasdataForm.length - indexo != 1" style="opacity:0.5;">
                                <v-icon style="color:white;" small>mdi-plus</v-icon>
                              </div>
                              <div class="button-minus" v-if="indexo != 0 && ormasLokasdataForm.length - indexo == 1">
                                <v-icon style="color:white;" small @click="ormasLokasdataForm.splice(-1)">mdi-minus</v-icon>
                              </div>
                              <div class="button-plus" v-if="ormasLokasdataForm.length - indexo == 1">
                                <v-icon style="color:white;" small @click="addOL()">mdi-plus</v-icon>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-5">
                <b-col class="d-flex justify-content-center pt-5 mt-5">
                  <button class="mt-16 text-center button-back mr-5" @click="kembaliMitra()">
                    Kembali
                  </button>
                  <b-button class="mt-16 text-center" variant="primary" size="lg" style="width:200px;" @click="lanjutMitra()">
                    Lanjut
                  </b-button>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Tenaga Kerja Asing" style="background-color:#FAFAFA;margin-top:0px;"><b-row> 
                <input style="display: none;" />
                <b-col cols="12">
                  <div class="table-responsive">
                    <table class="table table-head-custom table-vertical-center table-head-bg table-bordered">
                      <thead>
                        <tr class="text-left">
                          <th style="width: 20px">No</th>
                          <th style="min-width: 50px" class="text-capitalize">Nama Tenaga Kerja</th>
                          <th style="min-width: 50px" class="text-capitalize">Kewarganegaraan</th>
                          <th style="min-width: 50px" class="text-capitalize">Nomor Paspor/KITAP/KITAS</th>
                          <th style="width: 100px" class="text-capitalize">Aksi</th>
                        </tr>
                      </thead>
                      <tbody v-if="formanggotaAsing.length == 0">
                        <template v-if="!loadingTable">
                          <tr>
                            <td colspan="6"> -- Tidak ada data -- </td>
                          </tr>
                        </template>
                      </tbody>
                      <tbody style="background:#FAFAFA;"  v-if="formanggotaAsing.length !== 0">
                        <template v-for="(item, index) in formanggotaAsing">
                          <tr v-bind:key="index">
                            <td>
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                {{ index + 1 }}
                              </span>
                            </td>
                            <td>
                              <b-form-input v-model="item.nama" placeholder="silahkan masukkan nama tenaga kerja"></b-form-input>
                            </td>
                            <td>
                              <model-select :options="optionsNegara"
                                v-model="item.negaraKode" 
                                placeholder="silahkan pilih negara">
                              </model-select>
                            </td>
                            <td>
                              <b-form-input v-model="item.nomorIdentitas" placeholder="silahkan masukkan nomor paspor/kitab/kitas"></b-form-input>
                            </td>
                            <td class="d-flex justify-content-center">
                              <div class="button-plus" v-if="formanggotaAsing.length - index != 1" style="opacity:0.5;">
                                <v-icon style="color:white;" small>mdi-plus</v-icon>
                              </div>
                              <div class="button-minus" v-if="index != 0 && formanggotaAsing.length - index == 1">
                                <v-icon style="color:white;" small @click="formanggotaAsing.splice(-1)">mdi-minus</v-icon>
                              </div>
                              <div class="button-plus" v-if="formanggotaAsing.length - index == 1">
                                <v-icon style="color:white;" small @click="addTA()">mdi-plus</v-icon>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-5">
                <b-col class="d-flex justify-content-center pt-5 mt-5">
                  <button class="mt-16 text-center button-back mr-5" @click="kembaliTA()">
                    Kembali
                  </button>
                  <b-button class="mt-16 text-center" variant="primary" size="lg" style="width:200px;" @click="lanjutTA()">
                    Lanjut
                  </b-button>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Pelaporan" style="background-color:#FAFAFA;margin-top:0px;">
              <b-row>
                <input style="display: none;" />
                <b-col cols="12">
                  <b-row class="mt-5">
                    <b-col class="d-flex align-items-center justify-content-between">
                      <span style="font-size:16px; font-weight:bold;"></span>
                      <div>
                        <b-button size="sm" variant="primary" @click="tambahPelaporan()">
                          <v-icon style="color:white;" small>mdi-plus-box</v-icon>
                          Tambah Pelaporan
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                  <div class="table-responsive mt-4" style="border-radius:15px;">
                    <table class="table table-head-custom table-vertical-center table-head-bg table-bordered">
                      <thead>
                        <tr class="text-left">
                          <th style="width: 20px">No</th>
                          <th style="min-width: 50px" class="text-capitalize">Tanggal Laporan</th>
                          <th style="min-width: 50px" class="text-capitalize">Nama Pelapor</th>
                          <th style="min-width: 50px" class="text-capitalize">Jabatan Pelapor</th>
                          <th style="min-width: 50px" class="text-capitalize">Instansi</th>
                          <th style="width: 100px" class="text-capitalize">Aksi</th>
                        </tr>
                      </thead>
                      <tbody v-if="formpelaporanAsing.length == 0">
                        <template v-if="!loadingTable">
                          <tr>
                            <td colspan="6"> -- Tidak ada data -- </td>
                          </tr>
                        </template>
                      </tbody>
                      <tbody style="background:#FAFAFA;" v-if="formpelaporanAsing.length != 0">
                        <template v-for="(item, index) in formpelaporanAsing">
                          <tr v-bind:key="index">
                            <td>
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                {{ index + 1 }}
                              </span>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ item.tanggal | moment("DD-MM-YYYY") }}</span>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ item.namaPelapor }}</span>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ item.jabatan }}</span>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ item.instansi }}</span>
                            </td>
                            <td class="d-flex justify-content-center">
                              <b-button variant="primary" size="sm" class="d-flex justify-content-center align-items-center" style="width:30px; height:30px;margin:5px;" @click="editPelaporan(item, index)">
                                <v-icon style="color:white;padding:0px;" small>mdi-pencil-outline</v-icon>
                              </b-button>
                              <b-button variant="danger" size="sm" class="d-flex justify-content-center align-items-center" style="width:30px; height:30px;margin:5px;" @click="formpelaporanAsing.splice(index, 1)">
                                <v-icon style="color:white;padding:0px;" small>mdi-delete-outline</v-icon>
                              </b-button>
                              <b-button @click="toDetailPelaporan(item)" class="d-flex justify-content-center align-items-center" variant="success"
                                size="sm" style="width:30px;height:30px;margin:5px;">
                                >>
                              </b-button>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-5">
                <b-col class="d-flex justify-content-center pt-5 mt-5">
                  <button class="mt-16 text-center button-back mr-5" @click="kembaliPelaporan()">
                    Kembali
                  </button>
                  <b-button class="mt-16 text-center" variant="primary" size="lg" style="width:200px;" @click="ajukanForm()">
                    Ajukan
                  </b-button>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-container>
      </div>
    </b-modal>
    
    <!-- modal form Pelaporan-->
    <b-modal id="modal-formPelaporan-ormas" ok-only centered size="xl" content-class="shadow" hide-header hide-footer>
      <div>
        <b-container fluid class="pl-1 pr-1">
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col class="d-flex justify-content-between pr-0">
              <b style="font-size:20px;">
                Pelaporan Ormas WNA
              </b>
              <div class="align-items-center">
                <b-button 
                  variant="outline-secondary" 
                  style="background: #FFFFFF;" 
                  size="sm" 
                  class="mr-4"
                  @click="closeformPelaporan()">
                  <v-icon class="pr-3" style="padding-right: unset !important;">mdi-close</v-icon>
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Tanggal Laporan" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <b-form-datepicker 
                v-model="tanggalPel"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                placeholder="tgl lapor"
                style="height:40px;width:130px;" class="mb-2">
              </b-form-datepicker>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Nama Pelapor" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <b-form-input v-model="namaPelaporPel" placeholder="silahkan masukkan nama pelapor"></b-form-input>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Jabatan Pelapor" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <b-form-input v-model="jabatanPel" placeholder="silahkan masukkan jabatan pelapor"></b-form-input>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Instansi" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <b-form-input v-model="instansiPel" placeholder="silahkan masukkan instansi pelapor"></b-form-input>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Perihal Laporan" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <b-form-select  v-model="perihalPel">
                <b-form-select-option value=0 disabled hidden>silahkan masukkan jenis pelaporan</b-form-select-option>
                <b-form-select-option value=1>Laporan Kepatuhan</b-form-select-option>
                <b-form-select-option value=2>Progres Program</b-form-select-option>
              </b-form-select>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Isi Laporan" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <b-form-textarea v-model="isiLaporanPel" id="textarea" placeholder="silahkan masukkan isi laporan" rows="3" max-rows="6">
              </b-form-textarea>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 d-flex align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Unggah Lampiran" }}</p>
            </b-col>
            :
            <b-col sm="2">
              <b-button variant="primary" size="sm" v-if="!fileLaporanPel || fileLaporanPel == ''" @click="fileClicked('laporan')">
                <v-icon>
                  mdi-upload
                </v-icon>
                Unggah MSP
              </b-button>
              <a v-if="fileLaporanPel && fileLaporanPel != ''" :href="fileURL + fileLaporanPel" target="_blank" type="button">
                <b-button variant="success" size="sm">
                  Lihat File
                </b-button>
              </a>
              <b-button v-if="fileLaporanPel && fileLaporanPel != ''" @click="fileLaporanPel = ''" variant="danger" size="sm" class="ml-3">
                <v-icon class="p-0">
                  mdi-delete-outline
                </v-icon>
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col class="d-flex justify-content-center pt-5 mt-5">
              <button class="mt-16 text-center button-back mr-5" @click="closeformPelaporan()">
                Kembali
              </button>
              <b-button v-if="createMode == true" class="mt-16 text-center" variant="primary" size="lg" style="width:200px;" @click="savePelaporan()">
                Tambah
              </b-button>
              <b-button v-if="createMode == false" class="mt-16 text-center" variant="primary" size="lg" style="width:200px;" @click="simpaneditPelaporan(ideditpelaporan)">
                Ubah
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>

    <!-- modal form Lokus-->
    <b-modal id="modal-formLokus-ormas" ok-only centered size="xl" content-class="shadow" hide-header hide-footer>
      <div>
        <b-container fluid class="pl-1 pr-1">
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col class="d-flex justify-content-between pr-0">
              <b style="font-size:20px;">
                Lokus Kegiatan Ormas WNA
              </b>
              <div class="align-items-center">
                <b-button variant="outline-secondary" style="background: #FFFFFF;" size="sm" class="mr-4"
                  @click="closeformLokus()">
                  <v-icon class="pr-3" style="padding-right: unset !important;">mdi-close</v-icon>
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;" class="required">{{ "Propinsi" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <b-form-select :disabled="disProp" v-model="selectedPropinsi">
                <b-form-select-option value="" disabled>silahkan masukkan lokus propinsi</b-form-select-option>
                <b-form-select-option v-bind:value="{kode: item.kode,nama: item.nama}" v-for="(item,index) in listPropinsi" :key="index">{{item.nama}}
                </b-form-select-option>
              </b-form-select>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;" class="required">{{ "Kabupaten/Kota" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <b-form-select :disabled="disKab" v-model="selectedKabKot">
                <b-form-select-option value="" disabled>silahkan masukkan lokus kabupaten/kota</b-form-select-option>
                <b-form-select-option v-for="(itemm,indexm) in listKabkot" :key="indexm" v-bind:value="{kode: itemm.kode,nama: itemm.nama}">{{itemm.nama}}
                </b-form-select-option>
              </b-form-select>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Kecamatan" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <b-form-select :disabled="disKec" v-model="selectedKec">
                <b-form-select-option value="" disabled>silahkan masukkan lokus kecamatan</b-form-select-option>
                <b-form-select-option v-for="(itemms,indexms) in listKec" :key="indexms" v-bind:value="{kode: itemms.kode,nama: itemms.nama}">{{itemms.nama}}
                </b-form-select-option>
              </b-form-select>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Kelurahan/Desa" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <b-form-select :disabled="disKec" v-model="selectedKel">
                <b-form-select-option value="" disabled>silahkan masukkan lokus kelurahan/desa</b-form-select-option>
                <b-form-select-option v-for="(itemmss,indexmss) in listKel" :key="indexmss" v-bind:value="{kode: itemmss.kode,nama: itemmss.nama}">{{itemmss.nama}}
                </b-form-select-option>
              </b-form-select>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Jalan" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <b-form-input v-model="selectedJln" placeholder="silahkan masukkan lokus nama jalan"></b-form-input>
                </b-col>
              </b-row>
              <b-row class="mt-5">
            <b-col class="d-flex justify-content-center pt-5 mt-5">
              <button class="mt-16 text-center button-back mr-5" @click="closeformLokus()">
                Kembali
              </button>
              <b-button v-if="createMode == true" class="mt-16 text-center" variant="primary" size="lg" style="width:200px;" @click="saveLokus()">
                Tambah
              </b-button>
              <b-button v-if="createMode == false" class="mt-16 text-center" variant="primary" size="lg" style="width:200px;" @click="simpanedit(ideditlokus)">
                Ubah
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
    <!-- modal detail Lokus-->
    <b-modal id="modal-detailLokus-ormas" ok-only centered size="xl" content-class="shadow" hide-header hide-footer>
      <div>
        <b-container fluid class="pl-1 pr-1">
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col class="d-flex justify-content-between pr-0">
              <b style="font-size:20px;">
                Lokus Kegiatan Ormas WNA
              </b>
              <div class="align-items-center">
                <b-button variant="outline-secondary" style="background: #FFFFFF;" size="sm" class="mr-4"
                  @click="closeDetailLokus()">
                  <v-icon class="pr-3" style="padding-right: unset !important;">mdi-close</v-icon>
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Propinsi" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{this.selectedPropinsi.nama}}</span>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Kabupaten/Kota" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{this.selectedKabKot.nama}}</span>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Kecamatan" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{this.selectedKec.nama}}</span>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Kelurahan/Desa" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{this.selectedKel.nama}}</span>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Jalan" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{this.selectedJln}}</span>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>

    <!-- modal detail Pelaporan-->
    <b-modal id="modal-detailPelaporan-ormas" ok-only centered size="xl" content-class="shadow" hide-header hide-footer>
      <div>
        <b-container fluid class="pl-1 pr-1">
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col class="d-flex justify-content-between pr-0">
              <b style="font-size:20px;">
                Pelaporan Ormas WNA
              </b>
              <div class="align-items-center">
                <b-button 
                  variant="outline-secondary" 
                  style="background: #FFFFFF;" 
                  size="sm" 
                  class="mr-4"
                  @click="closeDetailPelaporan()">
                  <v-icon class="pr-3" style="padding-right: unset !important;">mdi-close</v-icon>
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Tanggal Laporan" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ this.tanggalPel | moment('DD - MM - YYYY') }}</span>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Nama Pelapor" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{this.namaPelaporPel}}</span>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Jabatan Pelapor" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{this.jabatanPel}}</span>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Instansi" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{this.instansiPel}}</span>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Perihal Laporan" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{this.perihalPel}}</span>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Isi Laporan" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{this.isiLaporanPel}}</span>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 d-flex align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Lampiran" }}</p>
            </b-col>
            :
            <b-col sm="2">
              <a v-if="this.fileLaporanPel && this.fileLaporanPel != ''" :href="fileURL + this.fileLaporanPel" target="_blank" type="button">
                <b-button variant="success" size="sm">
                  Lihat File
                </b-button>
              </a>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>

    <!-- modal detail penambahan -->
    
    <!-- modal detail cabang -->
    
    <!-- tampilan awal -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <div class="d-flex justify-content-between align-items-center p-1 pr-3" style="border-radius:8px; border:solid 1px;">
                <input
                  type="text"
                  class="form-control border-0"
                  :placeholder="'Cari...'"
                  v-model="search"
                  v-on:keyup.enter="searchSubmit()"
                />
                <b-button
                  size="sm"
                  style="width:80px;margin-right:10px;"
                  variant="primary"
                  @click="searchSubmit()"
                >
                  <v-icon
                    style="color:white;"
                    small
                  >mdi-magnify</v-icon>
                  Cari
                </b-button>
              </div>
            </b-col>
            <!-- <b-col cols="1" class="d-flex align-items-center p-0">
              <b-button
                size="sm"
                variant="primary"
                @click="showFilter()"
              >
                <v-icon
                  style="color:white;"
                  small
                >mdi-filter</v-icon>
                Filter
              </b-button>
            </b-col> -->
          </b-row>
          <!-- </b-card> -->
        </b-col>
      </b-row>

      <b-row class="mt-5">
        <b-col class="d-flex align-items-center justify-content-between">
          <span style="font-size:16px; font-weight:bold;">{{  !user.roles.includes('kesbangpol') ? 'Penambahan Ormas WNA' : 'Ormas yang Didirikan WNA' }}</span>
          <div v-if="!user.roles.includes('kesbangpol')">
            <b-button size="sm" variant="primary" @click="tambahOrmas()">
              <v-icon style="color:white;" small>mdi-plus-box</v-icon>
              Tambah Ormas WNA
            </b-button>
          </div>
        </b-col>
      </b-row>
      <div class="table-responsive mt-4" style="border-radius:15px;">
        <table class="table table-head-custom table-vertical-center table-head-bg table-bordered"
          style="font-size:14px !important;">
          <thead>
            <tr class="text-left">
              <th style="width: 20px" class="text-capitalize">No.</th>
              <th style="min-width: 50px" class="text-capitalize">Nama Ormas WNA</th>
              <th style="min-width: 50px" class="text-capitalize">Negara</th>
              <th style="min-width: 50px" class="text-capitalize">Mitra K/L</th>
              <th style="min-width: 50px" class="text-capitalize">Nomor MSP</th>
              <th style="width: 100px" class="text-capitalize" v-if="!user.roles.includes('kesbangpol')">Aksi</th>
            </tr>
          </thead>
          <tbody v-if="listOrmasAsing.length == 0">
            <template v-if="!loadingTable">
              <tr>
                <td colspan="6"> -- Tidak ada permohonan -- </td>
              </tr>
            </template>
          </tbody>
          <tbody style="background: #FFFFFF;">
            <template v-for="(item, i) in listOrmasAsing">
              <tr v-bind:key="i">
                <td>
                  <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                    {{(i + 1) + ((currentPage - 1)*perPage)}}
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ item.namaOrmas }}</span>
                </td>
                <td class="text-left">
                  <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ item.masterNegara.negara }}</span>
                </td>
                <td class="text-left">
                  <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ item.asingKemitraan ? item.asingKemitraan.mitraKl : '-' }}</span>
                </td>
                <td class="text-left">
                  <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ item.nomorMsp }}</span>
                </td>
                <td class="text-left" v-if="!user.roles.includes('kesbangpol')">
                  <span class="text-dark-75 font-weight-bolder text-left font-size-lg">
                    <section class="d-flex justify-content-center align-items-center">
                      <b-button variant="primary" size="sm" class="d-flex justify-content-center align-items-center"
                        style="width:30px;height:30px;margin:5px;" @click="callId(item.id)">
                        <v-icon style="color:white;padding:0px;" small>mdi-pencil-outline</v-icon>
                      </b-button>
                      <b-button variant="danger" size="sm" class="d-flex justify-content-center align-items-center"
                        style="width:30px;height:30px;margin:5px;" @click="deletePenambahan(item.id)">
                        <v-icon style="color:white;padding:0px;" small>mdi-delete-outline</v-icon>
                      </b-button>
                    </section>
                  </span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <b-row>
        <b-col class="d-flex justify-content-end">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" aria-controls="my-table"></b-pagination>
        </b-col>
      </b-row>
    </section>
  </div>
</template>
<style>
#search-card .card-body {
  padding: 0px !important;
}
</style>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import axios from "axios";
import { ModelSelect } from 'vue-search-select';
export default {
  mixins: [validationMixin],
  name: "Formtambahormasasing",
  components: {
      ModelSelect
  },
  data() {
    return {
      activeTab: 0,
      userType: '',
      totalRows: 0,
      totalRowss: 0,
      currentPage: 1,
      currentOffset: 0,
      search: '',
      query: {},
      perPage: 20,
      loadingTable: false,
      fileURL: process.env.VUE_APP_APPLICATION_FILE_URL + '/',
      optionsNegara: [],
      optionsOrmasSKT: [],
      formprofilAsing: {
        namaOrmas: '',
        negaraKode: '',
        benua: '',
        program: [
          { 
            nama: '' 
          }
        ],
        nomorMsp: '',
        fileNomorMsp: '',
        mulaiMsp: '',
        akhirMsp: '',
        fileAp: '',
        fileRik: ''
      },
      formlokusAsing: [
        {
          provinsiKode: '',
          kabupatenKotaKode: '',
          kecamatanKode: '',
          kelurahanKode: '',
          jalan: '',
          masterProvinsi: null,
          masterKabupatenKota: null,
          masterKecamatan: null,
          masterKelurahan: null
        }
      ],
      formmitraAsing: {
        mitraKl: '',
        mitraJabatan: '',
        mitraPejabat: '',
        ormasLokal: []
      },
      formanggotaAsing: [
        {
          nama: '',
          nomorIdentitas: '',
          negaraKode: ''
        }
      ],
      asingAnggotas: [
        {
          nama: '',
          nomorIdentitas: '',
          negaraKode: ''
        }
      ],
      formpelaporanAsing: [
        // {
        //   tanggal: '',
        //   namaPelapor: '',
        //   jabatan: '',
        //   instansi: '',
        //   isiLaporan: '',
        //   fileLaporan: '',
        //   perihal: 0
        // }
      ],
      tanggalPel: '',
      namaPelaporPel: '',
      jabatanPel: '',
      instansiPel: '',
      isiLaporanPel: '',
      fileLaporanPel: '',
      perihalPel: 0,
      asingPelaporans: [],
      listNegara: [],
      jenis_file: '',
      callIdMode: false,
      idPenambahan: null,
      listPropinsi: [],
      disProp: false,
      listKabkot: [],
      disKab: false,
      listKec: [],
      disKec: false,
      listKel: [],
      disKel: false,
      asingLokuses: [],
      sisaAktif: '',
      listOrmasAsing: [],
      listOrmas: [],
      lokusTempdata: [
        {
          provinsiKode: '',
          kabupatenKotaKode: '',
          kecamatanKode: '',
          kelurahanKode: '',
          jalan: '',
          masterProvinsi: null,
          masterKabupatenKota: null,
          masterKecamatan: null,
          masterKelurahan: null
        }
      ],
      KodeProv: '',
      selectedPropinsi: '',
      selectedKabKot: '',
      selectedKec: '',
      selectedKel: '',
      selectedJln: '',
      formmitraAsingskpd: [
        {
          nama: '',
          noRkt: '',
          namaPejabat: '',
          fileRkt: ''
        }
      ],
      createMode: true,
      ideditlokus: '',
      ideditpelaporan: '',
      ormasLokasdataForm: [
        {
          idOrmasLokal: 0,
          idOrmasBh: '',
          namaOrmasBh: '',
          jenisormasLokal: 0
        }
      ],
      asingKemitraanLkpds: [
      {
          nama: '',
          noRkt: '',
          namaPejabat: '',
          fileRkt: ''
        }
      ],
      asingKemitraan: {
        mitraKl: '',
        mitraJabatan: '',
        mitraPejabat: '',
        ormasLokal: []
      },
      masterNegara: null,
      user: JSON.parse(localStorage.getItem('user'))
    }
  },
  validations: {
    formprofilAsing: {
      namaOrmas: {required},
      negaraKode: {required},
      benua: {required},
      program: {
        $each: {
          nama: { required }
        }
      },
      nomorMsp: {required},
      fileNomorMsp: {required},
      mulaiMsp: {required},
      akhirMsp: {required},
      fileAp: {required},
      fileRik: {required}
    },
    // selectedPropinsi: { required },
    // selectedKabKot: {required},
    // formmitraAsing: {
    //   mitraKl: {required},
    //   mitraJabatan: {required},
    //   mitraPejabat: {required}
    // },
    // selectedPropinsi: {required}
  },
  mounted() {
    this.getListNegara()
    this.getPropinsi()
    this.getListOrmasAsing()
    this.getListOrmas()
  },
  watch: {
    activeTab(to){
      if (to == 1) {
        this.callId(localStorage.getItem('idPenambahanOrmas'))
      } else if (to == 2) {
        this.callId(localStorage.getItem('idPenambahanOrmas'))
      } else if (to == 3) {
        this.callId(localStorage.getItem('idPenambahanOrmas'))
      } else if (to == 4) {
        this.callId(localStorage.getItem('idPenambahanOrmas'))
      } 
    },
    selectedPropinsi(to) {
       this.getKabKot(to.kode)
    },
    selectedKabKot(to) {
      this.getKecamatan(to.kode)
    },
    selectedKec(to) {
      this.getKelurahan(to.kode)
    },
    currentPage(to){
      this.currentOffset = to - 1
      this.getListOrmasAsing()
    },
    search: {
      handler() {
        // Check if the user has stopped typing
        if (this.typingTimeout) {
          clearTimeout(this.typingTimeout);
        }
        this.typingTimeout = setTimeout(() => {
          // User has stopped typing, perform your action here
          this.searchSubmit()

        }, 500); // Adjust the delay as needed (1 second in this example)
      },
      immediate: false, // Set to true if you want to trigger it immediately
    }
  },
  methods: {
    async downloadItem(url, label, typeD) {
      const response = await axios.get(url, { responseType: "blob" });
      const blob = new Blob([response.data], { type: typeD });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    tambahOrmas(){
      this.resetForm()
      this.$v.$reset()
      this.callIdMode = false
      this.$bvModal.show('modal-form-ormas')
    },
    fileClicked(item) {
      this.jenis_file = item
      setTimeout(() => {
        this.$refs.fileInput.click()
      }, 500);
    },
    uploadFile(e) {
      var calculateSize = Math.floor(e.target.files[0].size / 1024)
      if (calculateSize > 15000) {
        Swal.fire({
          title: "Gagal Upload",
          text: 'Ukuran File Terlalu Besar',
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
        return false
      }
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      this.$store.dispatch('uploadFileGeneral', formData).then((res) => {
        if (res.data.message != 'Proses upload gagal') {
          Swal.fire({
            title: "Upload File Berhasil",
            text: '',
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then(() => {
            if (this.jenis_file == 'msp') this.formprofilAsing.fileNomorMsp = res.data.data.newFilePath
            if (this.jenis_file == 'ap') this.formprofilAsing.fileAp = res.data.data.newFilePath
            if (this.jenis_file == 'pik') this.formprofilAsing.fileRik = res.data.data.newFilePath
            for (var f = 0; f < this.formmitraAsingskpd.length; f++) {
              if (this.jenis_file == 'rkt' + f) {
                this.formmitraAsingskpd[f].fileRkt = res.data.data.newFilePath
              }
            }
            if (this.jenis_file == 'laporan') this.fileLaporanPel = res.data.data.newFilePath
            this.$forceUpdate()
          })
        }
        this.$refs.fileInput.value = null;
      }).catch((err) => {
        this.$refs.fileInputvalue = null;
        Swal.fire({
          title: "Upload File Gagal",
          text: err.message,
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
      })
    },
    resetForm(){
      this.$bvModal.hide('modal-form-ormas')
      localStorage.setItem('idPenambahanOrmas', null)
      this.formprofilAsing = {
        namaOrmas: '',
        negaraKode: '',
        benua: '',
        program: [{'nama' : ''}],
        nomorMsp: '',
        fileNomorMsp: '',
        mulaiMsp: '',
        akhirMsp: '',
        fileAp: '',
        fileRik: ''
      },
      this.asingKemitraan = {
        mitraKl: '',
        mitraJabatan: '',
        mitraPejabat: '',
        ormasLokal: []
      },
      this.formmitraAsingskpd = [ 
      {
          nama: '',
          noRkt: '',
          namaPejabat: '',
          fileRkt: ''
        }
      ],
      this.asingKemitraanLkpds = [],
      this.ormasLokasdataForm = [],
      this.formanggotaAsing = [
        {
          nama: '',
          nomorIdentitas: '',
          negaraKode: ''
        }
      ],
      this.asingAnggotas = [],
      this.formpelaporanAsing = [],
      this.asingPelaporans = [],
      this.sisaAktif = '',
      this.lokusTempdata = [],
      this.selectPropinsi = [],
      this.selectKabKot = [],
      this.selectKec = [],
      this.selectKel = [],
      this.selectJln = []
    },
    lanjutProfile() {
      this.$v.$touch();
      if (this.$v.$error) return

      var locus = []
      if (this.callIdMode == true && this.asingLokuses !== undefined) {
        for (var a = 0; a < this.asingLokuses.length; a++) {
          var aa = this.asingLokuses[a]
          if (aa.provinsiKode !== '' || aa.kabupatenKotaKode !== '') {
            locus.push({
              provinsiKode: aa.provinsiKode,
              kabupatenKotaKode: aa.kabupatenKotaKode,
              kecamatanKode: aa.kecamatanKode,
              kelurahanKode: aa.kelurahanKode,
              jalan: aa.jalan == null ? '' : aa.jalan
            })
            this.lokusTempdata = this.asingLokuses
          }else{
            this.lokusTempdata = []
          }
        }
      }
      var lkpd = []
      if (this.callIdMode == true && this.asingKemitraanLkpds !== undefined) {
        for (var b = 0; b < this.asingKemitraanLkpds.length; b++) {
          var bb = this.asingKemitraanLkpds[b]
          if (bb.nama !== '' || bb.noRkt !== '' || bb.namaPejabat !== '' || bb.fileRkt !== '') {
            lkpd.push(bb)
          }
        }
      }
      var asingOrmas = []
      if (this.asingKemitraan.ormasLokal.length !== 0) {
        for (var c = 0; c < this.asingKemitraan.ormasLokal.length; c++) {
          var cc = this.asingKemitraan.ormasLokal[c]
          if (cc.idOrmasLokal !== 0 || cc.idOrmasBh !== '' || cc.namaOrmasBh !== '') {
            asingOrmas.push(cc)
          }
        }
      }
      var anggota = []
      if (this.callIdMode == true && this.asingAnggotas !== undefined) {
        for (var d = 0; d < this.asingAnggotas.length; d++) {
          var dd = this.asingAnggotas[d]
          if (dd.nama !== '' || dd.nomorIdentitas !== '' || dd.negaraKode !== '') {
            anggota.push(dd)
          }
        }
      }
      var pelapor = []
      if (this.callIdMode == true && this.asingPelaporans !== undefined) {
        for (var e = 0; e < this.asingPelaporans.length; e++) {
          var ee = this.asingPelaporans[e]
          if (ee.tanggal !== '' || ee.namaPelapor !== '' || ee.jabatan !== '' || ee.instansi !== '' || ee.isiLaporan !== '' || ee.fileLaporan !== ''  || ee.perihal !== 0) {
            pelapor.push({
              tanggal: ee.tanggal.substring(0, 10),
              namaPelapor: ee.namaPelapor,
              jabatan: ee.jabatan,
              instansi: ee.instansi,
              isiLaporan: ee.isiLaporan,
              fileLaporan: ee.fileLaporan,
              perihal: parseInt(ee.perihal)
            })
          }
        }
      }
      var namaprogram = []
      for (var h = 0; h < this.formprofilAsing.program.length; h++) {
        var hh = this.formprofilAsing.program[h].nama
        if (hh !== '') {
          namaprogram.push(hh)
        }
      }
      if (this.asingKemitraan.mitraKl !== null || this.asingKemitraan.mitraJabatan !== null || this.asingKemitraan.mitraPejabat !== null) {
        this.formmitraAsing = {
          mitraKl: this.asingKemitraan.mitraKl,
          mitraJabatan: this.asingKemitraan.mitraJabatan,
          mitraPejabat: this.asingKemitraan.mitraPejabat
        }
      }
      var dataform = {
        profilAsing: {
          namaOrmas: this.formprofilAsing.namaOrmas,
          negaraKode: this.formprofilAsing.negaraKode,
          benua: this.formprofilAsing.benua,
          program: JSON.stringify(namaprogram),
          nomorMsp: this.formprofilAsing.nomorMsp,
          fileNomorMsp: this.formprofilAsing.fileNomorMsp,
          mulaiMsp: this.formprofilAsing.mulaiMsp.substring(0, 10),
          akhirMsp: this.formprofilAsing.akhirMsp.substring(0, 10),
          fileAp: this.formprofilAsing.fileAp,
          fileRik: this.formprofilAsing.fileRik
        },
        lokusAsing: locus,
        mitraAsing: {
          mitraKl: this.formmitraAsing.mitraKl,
          mitraJabatan: this.formmitraAsing.mitraJabatan,
          mitraPejabat: this.formmitraAsing.mitraPejabat,
          // ormasLokal: this.formmitraAsing.ormasLokal
        },
        mitraAsingLkpd: lkpd,
        mitraAsingOrmas: asingOrmas,
        anggotaAsing: anggota,
        pelaporanAsing: pelapor
      }
      if (dataform.mitraAsing.mitraKl == '' || dataform.mitraAsing.mitraKl == null) delete dataform.mitraAsing.mitraKl
      if (dataform.mitraAsing.mitraJabatan == '' || dataform.mitraAsing.mitraJabatan == null) delete dataform.mitraAsing.mitraJabatan
      if (dataform.mitraAsing.mitraPejabat == '' || dataform.mitraAsing.mitraPejabat == null) delete dataform.mitraAsing.mitraPejabat
      for (var la = 0; la < dataform.lokusAsing.length; la++){
        if (dataform.lokusAsing[la].kecamatanKode == '' || dataform.lokusAsing[la].kecamatanKode == null) delete dataform.lokusAsing[la].kecamatanKode
        if (dataform.lokusAsing[la].kelurahanKode == '' || dataform.lokusAsing[la].kelurahanKode == null) delete dataform.lokusAsing[la].kelurahanKode
        if (dataform.lokusAsing[la].jalan == '' || dataform.lokusAsing[la].jalan == null) delete dataform.lokusAsing[la].jalan
      }
      var path = this.callIdMode ? 'patchPenambahanOrmasAsingProfil' : 'submitPenambahanOrmasAsing'
      this.$store
        .dispatch(path, dataform)
        .then((res) => {
          var idnya = res.data.data.id
          this.idPenambahan = idnya
          localStorage.setItem('idPenambahanOrmas', idnya)
          this.activeTab = 1
          window.scrollTo(0, 0);
          Swal.fire({
            title: "Data Berhasil Disimpan",
            text: "",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          })
        }).catch(() => {
          Swal.fire({
            title: "Data Gagal Disimpan",
            text: "",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          })
        })
    },
    
    tambahLokus() {
      this.resetFormlokus()
      this.$bvModal.show('modal-formLokus-ormas')
      this.createMode = true
    },
    editLokus(item, index){
      this.resetFormlokus()
      this.$bvModal.show('modal-formLokus-ormas')
      this.createMode = false
      this.selectedKabKot = {
        kode: item.kabupatenKotaKode, nama: item.masterKabupatenKota
      }
      this.selectedPropinsi = {
        kode: item.provinsiKode, nama: item.masterProvinsi
      }
      this.selectedKec = {
        kode: item.kecamatanKode, nama: item.masterKecamatan
      }
      this.selectedKel = {
        kode: item.kelurahanKode, nama: item.masterKelurahan
      }
      this.selectedJln = item.jalan
      this.ideditlokus = index
    },
    resetFormlokus() {
      this.$bvModal.hide('modal-formLokus-ormas')
      this.selectedPropinsi = '',
      this.selectedKabKot = '',
      this.selectedKec = '',
      this.selectedKel = '',
      this.selectedJln = ''
    },
    saveLokus() {
      this.$v.$touch();
      if (this.$v.$error) return
      if (this.selectedPropinsi !== '' || this.selectedKabKot !== '') {
        this.asingLokuses.push({
          provinsiKode: this.selectedPropinsi.kode,
          kabupatenKotaKode: this.selectedKabKot.kode,
          kecamatanKode: this.selectedKec.kode ? this.selectedKec.kode : '',
          kelurahanKode: this.selectedKel.kode ? this.selectedKel.kode : '',
          jalan: this.selectedJln,
          masterProvinsi: this.selectedPropinsi.nama,
          masterKabupatenKota: this.selectedKabKot.nama,
          masterKecamatan: this.selectedKec.nama,
          masterKelurahan: this.selectedKel.nama
        });
      }

      this.lokusTempdata = this.asingLokuses;
      this.$bvModal.hide('modal-formLokus-ormas')
    }, 
    simpanedit (ideditlokus) {
      this.$v.$touch();
      if (this.$v.$error) return
      this.asingLokuses[ideditlokus].provinsiKode = this.selectedPropinsi.kode
      this.asingLokuses[ideditlokus].kabupatenKotaKode = this.selectedKabKot.kode
      this.asingLokuses[ideditlokus].kecamatanKode = this.selectedKec.kode ? this.selectedKec.kode : ''
      this.asingLokuses[ideditlokus].kelurahanKode = this.selectedKel.kode ? this.selectedKel.kode : ''
      this.asingLokuses[ideditlokus].jalan = this.selectedJln
      this.asingLokuses[ideditlokus].masterProvinsi = this.selectedPropinsi.nama
      this.asingLokuses[ideditlokus].masterKabupatenKota = this.selectedKabKot.nama
      this.asingLokuses[ideditlokus].masterKecamatan = this.selectedKec.nama
      this.asingLokuses[ideditlokus].masterKelurahan = this.selectedKel.nama

      this.lokusTempdata = this.asingLokuses;
      this.$bvModal.hide('modal-formLokus-ormas')
    },
    toDetailLokus(item){
      this.closeDetailLokus()
      this.$bvModal.show('modal-detailLokus-ormas')
      this.createMode = false
      
      this.selectedKabKot = {
        kode: item.kabupatenKotaKode, nama: item.masterKabupatenKota
      }
      this.selectedPropinsi = {
        kode: item.provinsiKode, nama: item.masterProvinsi
      }
      this.selectedKec = {
        kode: item.kecamatanKode, nama: item.masterKecamatan
      }
      this.selectedKel = {
        kode: item.kelurahanKode, nama: item.masterKelurahan
      }
      this.selectedJln = item.jalan
    },
    closeDetailLokus(){
      this.$bvModal.hide('modal-detailLokus-ormas')
      this.selectedPropinsi = '',
      this.selectedKabKot = '',
      this.selectedKec = '',
      this.selectedKel = '',
      this.selectedJln = ''
    },
    closeformLokus() {
      this.resetFormlokus()
      this.$bvModal.hide('modal-formLokus-ormas')
    },
    lanjutLokus() {
      if (this.lokusTempdata.length === 0){
        Swal.fire({
          title: "Silahkan Tambah Lokus Kegiatan",
          text: "",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            this.resetFormlokus()
            this.$bvModal.show('modal-formLokus-ormas')
            this.createMode = true
          }
        })
      }else{
        var locus = []
        for (var a = 0; a < this.lokusTempdata.length; a++) {
          var aa = this.lokusTempdata[a]
          if (aa.provinsiKode !== '' || aa.kabupatenKotaKode !== '') {
            locus.push({
              provinsiKode: aa.provinsiKode,
              kabupatenKotaKode: aa.kabupatenKotaKode,
              kecamatanKode: aa.kecamatanKode,
              kelurahanKode: aa.kelurahanKode,
              jalan: aa.jalan
            })
          }
        }
        var lkpd = []
        if (this.callIdMode == true && this.asingKemitraanLkpds !== undefined) {
          for (var b = 0; b < this.asingKemitraanLkpds.length; b++) {
            var bb = this.asingKemitraanLkpds[b]
            if (bb.nama !== '' || bb.noRkt !== '' || bb.namaPejabat !== '' || bb.fileRkt !== '') {
              lkpd.push(bb)
              this.formmitraAsingskpd = this.asingKemitraanLkpds
            }
          }
        } else {
          this.formmitraAsingskpd = [ 
            {
              nama: '',
              noRkt: '',
              namaPejabat: '',
              fileRkt: ''
            }
          ]
        }
        var asingOrmas = []
        if (this.asingKemitraan.ormasLokal.length !== 0) {
          for (var c = 0; c < this.asingKemitraan.ormasLokal.length; c++) {
            var cc = this.asingKemitraan.ormasLokal[c]
            if (cc.idOrmasLokal !== 0 || cc.idOrmasBh !== '' || cc.namaOrmasBh !== '') {
              asingOrmas.push(cc)
              this.ormasLokasdataForm = this.asingKemitraan.ormasLokal
              this.ormasLokasdataForm[c].jenisormasLokal = cc.idOrmasLokal !== 0 ? 2 : 1
            }
          }
        } else {
          this.ormasLokasdataForm = [
            {
              idOrmasLokal: 0,
              idOrmasBh: '',
              namaOrmasBh: '',
              jenisormasLokal: 0
            }
          ]
        }
        var anggota = []
        if (this.callIdMode == true && this.asingAnggotas !== undefined) {
          for (var d = 0; d < this.asingAnggotas.length; d++) {
            var dd = this.asingAnggotas[d]
            if (dd.nama !== '' || dd.nomorIdentitas !== '' || dd.negaraKode !== '') {
              anggota.push(dd)
            }
          }
        }
        if (this.callIdMode == true && this.asingPelaporans !== undefined) {
          this.formpelaporanAsing = this.asingPelaporans
        }
        var pelapor = []
        if (this.callIdMode == true && this.asingPelaporans !== undefined) {
          for (var e = 0; e < this.asingPelaporans.length; e++) {
            var ee = this.asingPelaporans[e]
            if (ee.tanggal !== '' || ee.namaPelapor !== '' || ee.jabatan !== '' || ee.instansi !== '' || ee.isiLaporan !== '' || ee.fileLaporan !== ''  || ee.perihal !== 0) {
              pelapor.push({
                tanggal: ee.tanggal.substring(0, 10),
                namaPelapor: ee.namaPelapor,
                jabatan: ee.jabatan,
                instansi: ee.instansi,
                isiLaporan: ee.isiLaporan,
                fileLaporan: ee.fileLaporan,
                perihal: parseInt(ee.perihal)
              })
            }
          }
        }
        var namaprogram = []
        for (var h = 0; h < this.formprofilAsing.program.length; h++) {
          var hh = this.formprofilAsing.program[h].nama
          if (hh !== '') {
            namaprogram.push(hh)
          }
        }
        if (this.asingKemitraan.mitraKl !== null || this.asingKemitraan.mitraJabatan !== null || this.asingKemitraan.mitraPejabat !== null) {
          this.formmitraAsing = {
            mitraKl: this.asingKemitraan.mitraKl,
            mitraJabatan: this.asingKemitraan.mitraJabatan,
            mitraPejabat: this.asingKemitraan.mitraPejabat
          }
        }
        var dataform = {
          profilAsing: {
            namaOrmas: this.formprofilAsing.namaOrmas,
            negaraKode: this.formprofilAsing.negaraKode,
            benua: this.formprofilAsing.benua,
            program: JSON.stringify(namaprogram),
            nomorMsp: this.formprofilAsing.nomorMsp,
            fileNomorMsp: this.formprofilAsing.fileNomorMsp,
            mulaiMsp: this.formprofilAsing.mulaiMsp.substring(0, 10),
            akhirMsp: this.formprofilAsing.akhirMsp.substring(0, 10),
            fileAp: this.formprofilAsing.fileAp,
            fileRik: this.formprofilAsing.fileRik
          },
          lokusAsing: locus,
          mitraAsing: {
            mitraKl: this.formmitraAsing.mitraKl,
            mitraJabatan: this.formmitraAsing.mitraJabatan,
            mitraPejabat: this.formmitraAsing.mitraPejabat,
            // ormasLokal: this.formmitraAsing.ormasLokal
          },
          mitraAsingLkpd: lkpd,
          mitraAsingOrmas: asingOrmas,
          anggotaAsing: anggota,
          pelaporanAsing: pelapor
        }
        if (dataform.mitraAsing.mitraKl == '' || dataform.mitraAsing.mitraKl == null) delete dataform.mitraAsing.mitraKl
        if (dataform.mitraAsing.mitraJabatan == '' || dataform.mitraAsing.mitraJabatan == null) delete dataform.mitraAsing.mitraJabatan
        if (dataform.mitraAsing.mitraPejabat == '' || dataform.mitraAsing.mitraPejabat == null) delete dataform.mitraAsing.mitraPejabat
        for (var la = 0; la < dataform.lokusAsing.length; la++){
          if (dataform.lokusAsing[la].kecamatanKode == '' || dataform.lokusAsing[la].kecamatanKode == null) delete dataform.lokusAsing[la].kecamatanKode
          if (dataform.lokusAsing[la].kelurahanKode == '' || dataform.lokusAsing[la].kelurahanKode == null) delete dataform.lokusAsing[la].kelurahanKode
          if (dataform.lokusAsing[la].jalan == '' || dataform.lokusAsing[la].jalan == null) delete dataform.lokusAsing[la].jalan
        }
        this.$store
          .dispatch("patchPenambahanOrmasAsingProfil", dataform)
          .then((res) => {
            var idnya = res.data.data.id
            this.idPenambahan = idnya
            localStorage.setItem('idPenambahanOrmas', idnya)
            this.activeTab = 2
            window.scrollTo(0, 0);
            Swal.fire({
              title: "Data Berhasil Disimpan",
              text: "",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Lanjut",
            })
          }).catch(() => {
            Swal.fire({
              title: "Data Gagal Disimpan",
              text: "",
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Lanjut",
            })
          })
      }
    },
    addSKPD() {
      this.formmitraAsingskpd.push({ nama: '', noRkt: '', namaPejabat: '', fileRkt: ''})
    },
    addOL() {
      this.ormasLokasdataForm.push({
        idOrmasLokal: 0,
        idOrmasBh: '',
        namaOrmasBh: '',
        jenisormasLokal: 0
      })
    },
    addTA() {
      this.formanggotaAsing.push({
        nama: '',
        nomorIdentitas: '',
        negaraKode: '',
      })
    },
    lanjutMitra() {
      this.$v.$touch();
      if (this.$v.$error) return

      var locus = []
      if (this.callIdMode == true && this.asingLokuses !== undefined) {
        for (var a = 0; a < this.asingLokuses.length; a++) {
          var aa = this.asingLokuses[a]
          if (aa.provinsiKode !== '' || aa.kabupatenKotaKode !== '') {
            locus.push({
              provinsiKode: aa.provinsiKode,
              kabupatenKotaKode: aa.kabupatenKotaKode,
              kecamatanKode: aa.kecamatanKode,
              kelurahanKode: aa.kelurahanKode,
              jalan: aa.jalan
            })
          }
        }
      }
      var lkpd = []
      for (var b = 0; b < this.formmitraAsingskpd.length; b++) {
        var bb = this.formmitraAsingskpd[b]
        if (bb.nama !== '' || bb.noRkt !== '' || bb.namaPejabat !== '' || bb.fileRkt !== '') {
            lkpd.push(bb)
        }
      }
      var asingOrmas = []
      for (var c = 0; c < this.ormasLokasdataForm.length; c++) {
        var cc = this.ormasLokasdataForm[c]
        if (cc.idOrmasLokal !== 0 || cc.idOrmasBh !== '' || cc.namaOrmasBh !== '' || cc.jenisormasLokal !== 0) {
          asingOrmas.push({
            idOrmasLokal: cc.idOrmasLokal,
            idOrmasBh: cc.idOrmasBh,
            namaOrmasBh: cc.namaOrmasBh,
          })
        }
      }
      var anggota = []
      if (this.callIdMode == true && this.asingAnggotas !== undefined) {
        for (var d = 0; d < this.asingAnggotas.length; d++) {
          var dd = this.asingAnggotas[d]
          if (dd.nama !== '' || dd.nomorIdentitas !== '' || dd.negaraKode !== '') {
            anggota.push(dd)
            this.formanggotaAsing = this.asingAnggotas
          }
        }
      } else {
        this.formanggotaAsing = [
          {
            nama: '',
            nomorIdentitas: '',
            negaraKode: ''
          }
        ]
      }
      if (this.callIdMode == true && this.asingPelaporans !== undefined) {
        this.formpelaporanAsing = this.asingPelaporans
      }
      var pelapor = []
      if (this.callIdMode == true && this.asingPelaporans !== undefined) {
        for (var e = 0; e < this.asingPelaporans.length; e++) {
          var ee = this.asingPelaporans[e]
          if (ee.tanggal !== '' || ee.namaPelapor !== '' || ee.jabatan !== '' || ee.instansi !== '' || ee.isiLaporan !== '' || ee.fileLaporan !== ''  || ee.perihal !== 0) {
            pelapor.push({
              tanggal: ee.tanggal.substring(0, 10),
              namaPelapor: ee.namaPelapor,
              jabatan: ee.jabatan,
              instansi: ee.instansi,
              isiLaporan: ee.isiLaporan,
              fileLaporan: ee.fileLaporan,
              perihal: parseInt(ee.perihal)
            })
          }
        }
      }
      var namaprogram = []
      for (var h = 0; h < this.formprofilAsing.program.length; h++) {
        var hh = this.formprofilAsing.program[h].nama
        if (hh !== '') {
          namaprogram.push(hh)
        }
      }
      if (this.asingKemitraan.mitraKl !== null || this.asingKemitraan.mitraJabatan !== null || this.asingKemitraan.mitraPejabat !== null) {
        this.formmitraAsing = {
          mitraKl: this.asingKemitraan.mitraKl,
          mitraJabatan: this.asingKemitraan.mitraJabatan,
          mitraPejabat: this.asingKemitraan.mitraPejabat
        }
      }
      var dataform = {
        profilAsing: {
          namaOrmas: this.formprofilAsing.namaOrmas,
          negaraKode: this.formprofilAsing.negaraKode,
          benua: this.formprofilAsing.benua,
          program: JSON.stringify(namaprogram),
          nomorMsp: this.formprofilAsing.nomorMsp,
          fileNomorMsp: this.formprofilAsing.fileNomorMsp,
          mulaiMsp: this.formprofilAsing.mulaiMsp.substring(0, 10),
          akhirMsp: this.formprofilAsing.akhirMsp.substring(0, 10),
          fileAp: this.formprofilAsing.fileAp,
          fileRik: this.formprofilAsing.fileRik
        },
        lokusAsing: locus,
        mitraAsing: {
          mitraKl: this.formmitraAsing.mitraKl,
          mitraJabatan: this.formmitraAsing.mitraJabatan,
          mitraPejabat: this.formmitraAsing.mitraPejabat,
          // ormasLokal: this.formmitraAsing.ormasLokal
          
        },
        
        mitraAsingLkpd: lkpd,
        mitraAsingOrmas: asingOrmas,
        anggotaAsing: anggota,
        pelaporanAsing: pelapor
      }
      if (dataform.mitraAsing.mitraKl == '' || dataform.mitraAsing.mitraKl == null) delete dataform.mitraAsing.mitraKl
      if (dataform.mitraAsing.mitraJabatan == '' || dataform.mitraAsing.mitraJabatan == null) delete dataform.mitraAsing.mitraJabatan
      if (dataform.mitraAsing.mitraPejabat == '' || dataform.mitraAsing.mitraPejabat == null) delete dataform.mitraAsing.mitraPejabat
      for (var la = 0; la < dataform.lokusAsing.length; la++){
        if (dataform.lokusAsing[la].kecamatanKode == '' || dataform.lokusAsing[la].kecamatanKode == null) delete dataform.lokusAsing[la].kecamatanKode
        if (dataform.lokusAsing[la].kelurahanKode == '' || dataform.lokusAsing[la].kelurahanKode == null) delete dataform.lokusAsing[la].kelurahanKode
        if (dataform.lokusAsing[la].jalan == '' || dataform.lokusAsing[la].jalan == null) delete dataform.lokusAsing[la].jalan
      }
      this.$store
        .dispatch("patchPenambahanOrmasAsingProfil", dataform)
        .then((res) => {
          var idnya = res.data.data.id
          this.idPenambahan = idnya
          localStorage.setItem('idPenambahanOrmas', idnya)
          this.activeTab = 3
          window.scrollTo(0, 0);
          Swal.fire({
            title: "Data Berhasil Disimpan",
            text: "",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          })
        }).catch(() => {
          Swal.fire({
            title: "Data Gagal Disimpan",
            text: "",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          })
        })
    },
    lanjutTA() {
      this.$v.$touch();
      if (this.$v.$error) return
      
      var locus = []
      if (this.callIdMode == true && this.asingLokuses !== undefined) {
        for (var a = 0; a < this.asingLokuses.length; a++) {
          var aa = this.asingLokuses[a]
          if (aa.provinsiKode !== '' || aa.kabupatenKotaKode !== '') {
            locus.push({
              provinsiKode: aa.provinsiKode,
              kabupatenKotaKode: aa.kabupatenKotaKode,
              kecamatanKode: aa.kecamatanKode,
              kelurahanKode: aa.kelurahanKode,
              jalan: aa.jalan
            })
          }
        }
      }
      var lkpd = []
      if (this.callIdMode == true && this.asingKemitraanLkpds !== undefined) {
        for (var b = 0; b < this.asingKemitraanLkpds.length; b++) {
          var bb = this.asingKemitraanLkpds[b]
          if (bb.nama !== '' || bb.noRkt !== '' || bb.namaPejabat !== '' || bb.fileRkt !== '') {
            lkpd.push(bb)
          }
        }
      }
      var asingOrmas = []
      if (this.asingKemitraan.ormasLokal.length !== 0) {
        for (var c = 0; c < this.asingKemitraan.ormasLokal.length; c++) {
          var cc = this.asingKemitraan.ormasLokal[c]
          if (cc.idOrmasLokal !== 0 || cc.idOrmasBh !== '' || cc.namaOrmasBh !== '') {
            asingOrmas.push(cc)
          }
        }
      }
      var anggota = []
      for (var d = 0; d < this.formanggotaAsing.length; d++) {
        var dd = this.formanggotaAsing[d]
        if (dd.nama !== '' || dd.nomorIdentitas !== '' || dd.negaraKode !== '') {
          anggota.push(dd)
        }
      }
      if (this.callIdMode == true && this.asingPelaporans !== undefined) {
        this.formpelaporanAsing = this.asingPelaporans
      }
      var pelapor = []
      if (this.callIdMode == true && this.asingPelaporans !== undefined) {
        for (var e = 0; e < this.asingPelaporans.length; e++) {
          var ee = this.asingPelaporans[e]
          if (ee.tanggal !== '' || ee.namaPelapor !== '' || ee.jabatan !== '' || ee.instansi !== '' || ee.isiLaporan !== '' || ee.fileLaporan !== ''  || ee.perihal !== 0) {
            pelapor.push({
              tanggal: ee.tanggal.substring(0, 10),
              namaPelapor: ee.namaPelapor,
              jabatan: ee.jabatan,
              instansi: ee.instansi,
              isiLaporan: ee.isiLaporan,
              fileLaporan: ee.fileLaporan,
              perihal: parseInt(ee.perihal)
            })
            this.formpelaporanAsing = this.asingPelaporans
          }
        }
      }
      var namaprogram = []
      for (var h = 0; h < this.formprofilAsing.program.length; h++) {
        var hh = this.formprofilAsing.program[h].nama
        if (hh !== '') {
          namaprogram.push(hh)
        }
      }
      if (this.asingKemitraan.mitraKl !== null || this.asingKemitraan.mitraJabatan !== null || this.asingKemitraan.mitraPejabat !== null) {
        this.formmitraAsing = {
          mitraKl: this.asingKemitraan.mitraKl,
          mitraJabatan: this.asingKemitraan.mitraJabatan,
          mitraPejabat: this.asingKemitraan.mitraPejabat
        }
      }
      var dataform = {
        profilAsing: {
          namaOrmas: this.formprofilAsing.namaOrmas,
          negaraKode: this.formprofilAsing.negaraKode,
          benua: this.formprofilAsing.benua,
          program: JSON.stringify(namaprogram),
          nomorMsp: this.formprofilAsing.nomorMsp,
          fileNomorMsp: this.formprofilAsing.fileNomorMsp,
          mulaiMsp: this.formprofilAsing.mulaiMsp.substring(0, 10),
          akhirMsp: this.formprofilAsing.akhirMsp.substring(0, 10),
          fileAp: this.formprofilAsing.fileAp,
          fileRik: this.formprofilAsing.fileRik
        },
        lokusAsing: locus,
        mitraAsing: {
          "mitraKl": this.formmitraAsing.mitraKl,
          "mitraJabatan": this.formmitraAsing.mitraJabatan,
          "mitraPejabat": this.formmitraAsing.mitraPejabat,
          // ormasLokal: this.formmitraAsing.ormasLokal
        },
        mitraAsingLkpd: lkpd,
        mitraAsingOrmas: asingOrmas,
        anggotaAsing: anggota,
        pelaporanAsing: pelapor
      }
      if (dataform.mitraAsing.mitraKl == '' || dataform.mitraAsing.mitraKl == null) delete dataform.mitraAsing.mitraKl
      if (dataform.mitraAsing.mitraJabatan == '' || dataform.mitraAsing.mitraJabatan == null) delete dataform.mitraAsing.mitraJabatan
      if (dataform.mitraAsing.mitraPejabat == '' || dataform.mitraAsing.mitraPejabat == null) delete dataform.mitraAsing.mitraPejabat
      for (var la = 0; la < dataform.lokusAsing.length; la++){
        if (dataform.lokusAsing[la].kecamatanKode == '' || dataform.lokusAsing[la].kecamatanKode == null) delete dataform.lokusAsing[la].kecamatanKode
        if (dataform.lokusAsing[la].kelurahanKode == '' || dataform.lokusAsing[la].kelurahanKode == null) delete dataform.lokusAsing[la].kelurahanKode
        if (dataform.lokusAsing[la].jalan == '' || dataform.lokusAsing[la].jalan == null) delete dataform.lokusAsing[la].jalan
      }
      this.$store
        .dispatch("patchPenambahanOrmasAsingProfil", dataform)
        .then((res) => {
          var idnya = res.data.data.id
          this.idPenambahan = idnya
          localStorage.setItem('idPenambahanOrmas', idnya)
          this.activeTab = 4
          window.scrollTo(0, 0);
          Swal.fire({
            title: "Data Berhasil Disimpan",
            text: "",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          })
        }).catch(() => {
          Swal.fire({
            title: "Data Gagal Disimpan",
            text: "",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          })
        })
    },
    kembaliPelaporan() {
      this.activeTab = 3
      window.scrollTo(0, 0)
    },
    kembaliTA() {
      this.activeTab = 2
      window.scrollTo(0, 0)
    },
    kembaliMitra() {
      this.activeTab = 1
      window.scrollTo(0, 0)
    },
    kembaliLokus() {
      this.activeTab = 0
      window.scrollTo(0, 0)
    },
    tambahPelaporan() {
      this.resetFormpelaporan()
      this.$bvModal.show('modal-formPelaporan-ormas')
      this.createMode = true
    },
    editPelaporan(item, index){
      this.resetFormpelaporan()
      this.$bvModal.show('modal-formPelaporan-ormas')
      this.createMode = false
      this.tanggalPel = item.tanggal
      this.namaPelaporPel = item.namaPelapor
      this.jabatanPel = item.jabatan
      this.instansiPel = item.instansi
      this.isiLaporanPel = item.isiLaporan
      this.fileLaporanPel = item.fileLaporan
      this.perihalPel = item.perihal
      this.ideditpelaporan = index
    },
    resetFormpelaporan() {
      this.$bvModal.hide('modal-formPelaporan-ormas')
      this.tanggalPel = '',
      this.namaPelaporPel = '',
      this.jabatanPel = '',
      this.instansiPel = '',
      this.isiLaporanPel = '',
      this.fileLaporanPel = '',
      this.perihalPel = 0
    },
    savePelaporan() {
      if (this.tanggalPel !== '' || this.namaPelaporPel !== '' || this.jabatanPel !== '' || this.instansiPel !== '' || this.isiLaporanPel !== '' || this.fileLaporanPel !== '' || this.perihalPel !== 0) {
      this.formpelaporanAsing.push({
        tanggal: this.tanggalPel,
        namaPelapor: this.namaPelaporPel,
        jabatan: this.jabatanPel,
        instansi: this.instansiPel,
        isiLaporan: this.isiLaporanPel,
        fileLaporan: this.fileLaporanPel,
        perihal: this.perihalPel
      })
      }
      this.$bvModal.hide('modal-formPelaporan-ormas')
    }, 
    simpaneditPelaporan (ideditpelaporan) {
       this.formpelaporanAsing[ideditpelaporan].tanggal = this.tanggalPel
       this.formpelaporanAsing[ideditpelaporan].namaPelapor = this.namaPelaporPel
       this.formpelaporanAsing[ideditpelaporan].jabatan = this.jabatanPel
       this.formpelaporanAsing[ideditpelaporan].instansi = this.instansiPel
       this.formpelaporanAsing[ideditpelaporan].isiLaporan = this.isiLaporanPel
       this.formpelaporanAsing[ideditpelaporan].fileLaporan = this.fileLaporanPel
       this.formpelaporanAsing[ideditpelaporan].perihal = this.perihalPel
      this.$bvModal.hide('modal-formPelaporan-ormas')
    },
    toDetailPelaporan(item){
      this.closeDetailPelaporan()
      this.$bvModal.show('modal-detailPelaporan-ormas')
      this.createMode = false
      this.tanggalPel = item.tanggal
      this.namaPelaporPel = item.namaPelapor
      this.jabatanPel = item.jabatan
      this.instansiPel = item.instansi
      this.isiLaporanPel = item.isiLaporan
      this.fileLaporanPel = item.fileLaporan
      this.perihalPel = item.perihal
    },
    closeDetailPelaporan(){
      this.$bvModal.hide('modal-detailPelaporan-ormas')
      this.tanggalPel = '',
      this.namaPelaporPel = '',
      this.jabatanPel = '',
      this.instansiPel = '',
      this.isiLaporanPel = '',
      this.fileLaporanPel = '',
      this.perihalPel = 0
    },
    closeformPelaporan() {
      this.resetFormpelaporan()
      this.$bvModal.hide('modal-formPelaporan-ormas')
    },
    ajukanForm(){
      this.$v.$touch();
      if (this.$v.$error) return

      var locus = []
      if (this.callIdMode == true && this.asingLokuses !== undefined) {
        for (var a = 0; a < this.asingLokuses.length; a++) {
          var aa = this.asingLokuses[a]
          if (aa.provinsiKode !== '' || aa.kabupatenKotaKode !== '') {
            locus.push({
              provinsiKode: aa.provinsiKode,
              kabupatenKotaKode: aa.kabupatenKotaKode,
              kecamatanKode: aa.kecamatanKode,
              kelurahanKode: aa.kelurahanKode,
              jalan: aa.jalan
            })
          }
        }
      }
      var lkpd = []
      if (this.callIdMode == true && this.asingKemitraanLkpds !== undefined) {
        for (var b = 0; b < this.asingKemitraanLkpds.length; b++) {
          var bb = this.asingKemitraanLkpds[b]
          if (bb.nama !== '' || bb.noRkt !== '' || bb.namaPejabat !== '' || bb.fileRkt !== '') {
            lkpd.push(bb)
          }
        }
      }
      var asingOrmas = []
      if (this.asingKemitraan.ormasLokal.length !== 0) {
        for (var c = 0; c < this.asingKemitraan.ormasLokal.length; c++) {
          var cc = this.asingKemitraan.ormasLokal[c]
          if (cc.idOrmasLokal !== 0 || cc.idOrmasBh !== '' || cc.namaOrmasBh !== '') {
            asingOrmas.push(cc)
          }
        }
      }
      var anggota = []
      if (this.callIdMode == true && this.asingAnggotas !== undefined) {
        for (var d = 0; d < this.asingAnggotas.length; d++) {
          var dd = this.asingAnggotas[d]
          if (dd.nama !== '' || dd.nomorIdentitas !== '' || dd.negaraKode !== '') {
            anggota.push(dd)
          }
        }
      }
      var pelapor = []
      if (this.callIdMode == true && this.formpelaporanAsing !== undefined) {
        for (var e = 0; e < this.formpelaporanAsing.length; e++) {
          var ee = this.formpelaporanAsing[e]
          if (ee.tanggal !== '' || ee.namaPelapor !== '' || ee.jabatan !== '' || ee.instansi !== '' || ee.isiLaporan !== '' || ee.fileLaporan !== ''  || ee.perihal !== 0) {
            pelapor.push({
              tanggal: ee.tanggal.substring(0, 10),
              namaPelapor: ee.namaPelapor,
              jabatan: ee.jabatan,
              instansi: ee.instansi,
              isiLaporan: ee.isiLaporan,
              fileLaporan: ee.fileLaporan,
              perihal: parseInt(ee.perihal)
            })
          }
        }
      }
      var namaprogram = []
      for (var h = 0; h < this.formprofilAsing.program.length; h++) {
        var hh = this.formprofilAsing.program[h].nama
        if (hh !== '') {
          namaprogram.push(hh)
        }
      }
      if (this.asingKemitraan.mitraKl !== null || this.asingKemitraan.mitraJabatan !== null || this.asingKemitraan.mitraPejabat !== null) {
        this.formmitraAsing = {
          mitraKl: this.asingKemitraan.mitraKl,
          mitraJabatan: this.asingKemitraan.mitraJabatan,
          mitraPejabat: this.asingKemitraan.mitraPejabat
        }
      }
      var dataform = {
        profilAsing: {
          namaOrmas: this.formprofilAsing.namaOrmas,
          negaraKode: this.formprofilAsing.negaraKode,
          benua: this.formprofilAsing.benua,
          program: JSON.stringify(namaprogram),
          nomorMsp: this.formprofilAsing.nomorMsp,
          fileNomorMsp: this.formprofilAsing.fileNomorMsp,
          mulaiMsp: this.formprofilAsing.mulaiMsp.substring(0, 10),
          akhirMsp: this.formprofilAsing.akhirMsp.substring(0, 10),
          fileAp: this.formprofilAsing.fileAp,
          fileRik: this.formprofilAsing.fileRik
        },
        lokusAsing: locus,
        mitraAsing: {
          mitraKl: this.formmitraAsing.mitraKl,
          mitraJabatan: this.formmitraAsing.mitraJabatan,
          mitraPejabat: this.formmitraAsing.mitraPejabat,
          // ormasLokal: this.formmitraAsing.ormasLokal
        },
        mitraAsingLkpd: lkpd,
        mitraAsingOrmas: asingOrmas,
        anggotaAsing: anggota,
        pelaporanAsing: pelapor
      }
      if (dataform.mitraAsing.mitraKl == '' || dataform.mitraAsing.mitraKl == null) delete dataform.mitraAsing.mitraKl
      if (dataform.mitraAsing.mitraJabatan == '' || dataform.mitraAsing.mitraJabatan == null) delete dataform.mitraAsing.mitraJabatan
      if (dataform.mitraAsing.mitraPejabat == '' || dataform.mitraAsing.mitraPejabat == null) delete dataform.mitraAsing.mitraPejabat
      for (var la = 0; la < dataform.lokusAsing.length; la++){
        if (dataform.lokusAsing[la].kecamatanKode == '' || dataform.lokusAsing[la].kecamatanKode == null) delete dataform.lokusAsing[la].kecamatanKode
        if (dataform.lokusAsing[la].kelurahanKode == '' || dataform.lokusAsing[la].kelurahanKode == null) delete dataform.lokusAsing[la].kelurahanKode
        if (dataform.lokusAsing[la].jalan == '' || dataform.lokusAsing[la].jalan == null) delete dataform.lokusAsing[la].jalan
      }
      this.$store
        .dispatch("patchPenambahanOrmasAsingProfil", dataform)
        .then((res) => {
          var idnya = res.data.data.id
          this.idPenambahan = idnya
          localStorage.setItem('idPenambahanOrmas', idnya)
          this.$bvModal.hide('modal-form-ormas')
          Swal.fire({
            title: "Data Berhasil Disimpan",
            text: "",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          })
        }).catch(() => {
          Swal.fire({
            title: "Data Gagal Disimpan",
            text: "",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          })
        })
    },
    getListNegara() {
      this.$store
      .dispatch("getListNegara")
      .then((res) => {
        this.$nextTick(() => {
          this.listNegara = res.data
          for(var i = 0; i < res.data.length; i++){
            this.optionsNegara.push({value: res.data[i].kode , text: res.data[i].negara})
          }
        })
      })
    },
    
    async getCountListOrmasAsing(q) {
      this.$store.dispatch("getListPenambahanOrmasAsingCount", q).then((res) => {
        this.totalRows = res.data.count
      })
    },

    getListOrmasAsing() {
      this.loadingTable = true
      this.query.offset = this.currentOffset
      this.getCountListOrmasAsing(this.query)

      this.$store.dispatch("getListPenambahanOrmasAsing", this.query).then((res) => {
        this.$nextTick(() => {
          this.loadingTable = false
          this.listOrmasAsing = res.data
        })
      })
    },
    callId(id) {
      // this.resetForm()
      this.callIdMode = true
      this.$store
        .dispatch("getDetailPenambahanOrmasAsing", id)
        .then((res) => {
          try {
            this.callIdMode = true
            localStorage.setItem('idPenambahanOrmas', res.data.id)
            this.idPenambahan = res.data.id
            var i = res.data
            this.formprofilAsing.namaOrmas = i.namaOrmas === null ? '' : i.namaOrmas
            this.formprofilAsing.negaraKode = i.negaraKode === null ? '' : i.negaraKode
            this.formprofilAsing.benua = i.benua === null ? '' : i.benua
            var programss = []

            for (var r = 0; r < JSON.parse(i.program).length; r++) {
              var rr = JSON.parse(i.program)[r]
              programss.push({
                nama: rr
              })
            }

            this.formprofilAsing.program = programss
            this.formprofilAsing.nomorMsp = i.nomorMsp === null ? '' : i.nomorMsp
            this.formprofilAsing.fileNomorMsp = i.fileNomorMsp === null ? '' : i.fileNomorMsp
            this.formprofilAsing.mulaiMsp = i.mulaiMsp === null ? '' : i.mulaiMsp
            this.formprofilAsing.akhirMsp = i.akhirMsp === null ? '' : i.akhirMsp
            var present_date = new Date();
            var dEnd = new Date(this.formprofilAsing.akhirMsp);
            var Result = Math.round(dEnd.getTime() - present_date.getTime()) / (1000 * 3600 * 24);
            var Tahun = Result / 365
            var Bulan = Tahun * 12
            var Hari = Bulan * 30

            if (this.formprofilAsing.akhirMsp === '') {
              this.sisaAktif = ''
            }

            if (Tahun < 1) {
              this.sisaAktif = Math.floor(Bulan) + ' Bulan'
              if (Bulan < 1) {
                this.sisaAktif = Math.floor(Hari) + ' Hari'
              }
            } else {
              this.sisaAktif = Math.floor(Tahun) + ' Tahun'
            }

            this.formprofilAsing.fileAp = i.fileAp === null ? '' : i.fileAp
            this.formprofilAsing.fileRik = i.fileRik === null ? '' : i.fileRik

            if (i.asingLokuses != undefined) {
              var lokuss = []
              for (var j = 0; j < i.asingLokuses.length; j++) {
                var jj = i.asingLokuses[j]
                lokuss.push({
                  provinsiKode: jj.provinsiKode,
                  kabupatenKotaKode: jj.kabupatenKotaKode,
                  kecamatanKode: jj.kecamatanKode ? jj.kecamatanKode : '',
                  kelurahanKode: jj.kelurahanKode ? jj.kelurahanKode : '',
                  jalan: jj.jalan,
                  masterProvinsi: jj.masterProvinsi ? jj.masterProvinsi.nama : '',
                  masterKabupatenKota: jj.masterKabupatenKota ? jj.masterKabupatenKota.nama : '',
                  masterKecamatan: jj.masterKecamatan ? jj.masterKecamatan.nama : '',
                  masterKelurahan: jj.masterKelurahan ? jj.masterKelurahan.nama : ''
                })
              }
              this.asingLokuses = lokuss
            }

            // Kemitraan K/L (Header)
            if (
              i.asingKemitraan.mitraKl !== null 
              || i.asingKemitraan.mitraJabatan !== null 
              || i.asingKemitraan.mitraPejabat !== null 
              || i.asingKemitraan.ormasLokal.length !== 0
            ){
              this.asingKemitraan = i.asingKemitraan
              this.formmitraAsing = this.asingKemitraan

              // Kemitraan Ormas Lokal
              if (i.asingKemitraan.ormasLokal.length > 0) {
                var ormasLokals = [];

                for (let k = 0; k < i.asingKemitraan.ormasLokal.length; k++) {
                  let ormasLokal = i.asingKemitraan.ormasLokal[k]
                  let jenisormasLokal = ormasLokal.idOrmasBh.length > 0 ? 1 : 2;

                  ormasLokals.push({
                    idOrmasBh: ormasLokal.idOrmasBh, 
                    idOrmasLokal: ormasLokal.idOrmasLokal, 
                    namaOrmasBh: ormasLokal.namaOrmasBh,
                    jenisormasLokal
                  })
                }

                this.ormasLokasdataForm = ormasLokals;
              }
            }

            // Kemitraan LKPDS
            if (i.asingKemitraanLkpds != undefined) {
              var Lkpdss = []
              for (var k = 0; k < i.asingKemitraanLkpds.length; k++) {
                var kk = i.asingKemitraanLkpds[k]
                Lkpdss.push({
                  nama: kk.nama,
                  noRkt: kk.noRkt,
                  namaPejabat: kk.namaPejabat,
                  fileRkt: kk.fileRkt
                })
              }
              this.asingKemitraanLkpds = Lkpdss
              this.formmitraAsingskpd = this.asingKemitraanLkpds
            }

            if (i.asingAnggotas != undefined) {
              var anggotass = []
              for (var m = 0; m < i.asingAnggotas.length; m++) {
                var mm = i.asingAnggotas[m]
                anggotass.push({
                  nama: mm.nama,
                  nomorIdentitas: mm.nomorIdentitas,
                  negaraKode: mm.negaraKode
                })
              }

              this.asingAnggotas = anggotass;
              this.formanggotaAsing = this.asingAnggotas;
            }

            if (i.asingPelaporans != undefined) {
              var pelaporanss = []
              for (var n = 0; n < i.asingPelaporans.length; n++) {
                var nn = i.asingPelaporans[n]
                pelaporanss.push({
                  tanggal: nn.tanggal,
                  namaPelapor: nn.namaPelapor,
                  jabatan: nn.jabatan,
                  instansi: nn.instansi,
                  isiLaporan: nn.isiLaporan,
                  fileLaporan: nn.fileLaporan,
                  perihal: nn.perihal
                })
              }

              this.asingPelaporans = pelaporanss
              this.formpelaporanAsing = this.asingPelaporans
            }
          } catch (i) {
            Swal.fire({
              title: "Data Gagal Ditampilkan",
              text: "",
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Lanjut",
            })
          }
          this.$bvModal.show('modal-form-ormas')
        })
    },
    getPropinsi() {
      this.$store
        .dispatch("getPropinsi")
        .then((res) => {
          this.$nextTick(() => {
            this.listPropinsi = res.data.data
          })
        })
    },
    getKabKot(id) {
      this.$store
        .dispatch("getWilayahId", id)
        .then((res) => {
          this.$nextTick(() => {
            // if (kabkotaProfil != null) {
            //   this.formProfil.kabupaten_kota_sekretariat = kabkotaProfil
            // }
            this.listKabkot = res.data.data
          })
        })
    },
    getKecamatan(id) {
      this.$store
        .dispatch("getWilayahId", id)
        .then((res) => {
          this.$nextTick(() => {
            // if (kabkotaProfil != null) {
            //   this.formProfil.kabupaten_kota_sekretariat = kabkotaProfil
            // }
            this.listKec = res.data.data
          })
        })
    },
    getKelurahan(id) {
      this.$store
        .dispatch("getWilayahId", id)
        .then((res) => {
          this.$nextTick(() => {
            // if (kabkotaProfil != null) {
            //   this.formProfil.kabupaten_kota_sekretariat = kabkotaProfil
            // }
            this.listKel = res.data.data
          })
        })
    },
    jenisOrmas(indexo) {
      this.ormasLokasdataForm[indexo].jenisormasLokal = this.ormasLokasdataForm[indexo].jenisormasLokal
    },
    calculateDateDiff() {
      var present_date = new Date();
      var dEnd = new Date(this.formprofilAsing.akhirMsp);
      var Result = Math.round(dEnd.getTime() - present_date.getTime()) / (1000 * 3600 * 24);
      var Tahun = Result / 365
      var Bulan = Tahun * 12
      var Hari = Bulan * 30
      if (this.formprofilAsing.akhirMsp === '') {
        this.sisaAktif = ''
      }
      if (Tahun < 1) {
        this.sisaAktif = Math.floor(Bulan) + ' Bulan'
        if (Bulan < 1){
          this.sisaAktif = Math.floor(Hari) + ' Hari'
        }
      } else {
        this.sisaAktif = Math.floor(Tahun) + ' Tahun'
      }
    },
    validateStateProgram(index) {
      const { $dirty, $error } = this.$v.formprofilAsing.program.$each.$iter[index];
      return $dirty ? !$error : null;
    },
    // validateStateProvinsiKode() {
    //   const { $error } = this.$v.selectedPropinsi;
    //   return $error ? !$error : null;
    // },
    // validateStateKabupatenKotaKode() {
    //   const { $error } = this.$v.selectedKabKot;
    //   return $error ? !$error : null;
    // },
    deletePenambahan(id) {
      const context = this
      Swal.fire({
        title: "Apakah Anda Yakin Ingin Menghapus Penambahan Ormas Asing?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Hapus",
      }).then((result) => {
        if (result.isConfirmed) {
          context.$store.dispatch("deletePenambahanAsing", id).then((/*res*/) => {
            Swal.fire({
              title: "Penambahan Ormas Asing berhasil dihapus",
              text: "",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Lanjut",
            }).then(() => {
              context.getListOrmasAsing()
            })
          })
        }
      });
    },
    getListOrmas() {
      this.$store
      .dispatch("getListOrmas")
      .then((res) => {
        this.$nextTick(() => {
          this.listOrmas = res.data
          for(var i = 0; i < res.data.length; i++){
            this.optionsOrmasSKT.push({value: res.data[i].id , text: res.data[i].nama_ormas})
          }
        })
      })
    },
    searchSubmit(){
      this.query.no = this.search

      if (this.query.no.length > 0 && this.query.no.length < 3) return

      this.getListOrmasAsing()
    },
  },
}
</script>

<style>
.nav.nav-pills .nav-link {
  color: #053A69;
  font-weight: bold;
}

.button-plus {
  background: #339933;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  margin-left: 5px;

}

.button-minus {
  background: #FF4433;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  margin-left: 5px;
}

.nav.nav-pills .nav-item {
  margin-right: 10px;
  margin-left: 10px;
}

.form-control.is-invalid {
  padding-right: 0px !important;
}

.form-control.is-valid {
  padding-right: 0px !important;
}

#modal-form-ormas .modal-dialog.modal-xl {
  max-width: 100% !important;
  margin-left: 1rem;
  margin-right: 1rem;
}
.required:after {
    content: " * ";
    color: red;
}
</style>